$light: #eff2f7;
$border: #c9d5e0;

* {
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
	color: inherit;
}

html {
    height: 100%;
    font-size: 10px;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    //font-family: 'Helvetica Neue';
    font-size: 1.6rem;
    line-height: 1.5;
    color: #5a6169;
}

.wrapper {
    width: 100;
	display: flex;
    flex: 1;
	flex-direction: row;
    
    .main {
		overflow: auto;
		flex: 1;
		min-height: 100vh;
		padding: 20px 60px 100px;
		max-width: 900px;
    }
	
	.title {
		border-bottom: solid 1px $light;
		margin-bottom: 2.2rem;
		
		.seperator {
			margin: 0 1rem;
			display: inline-block;
			height: 28px;
			width: 28px;
			vertical-align: middle;
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNMjk4LjMsMjU2TDI5OC4zLDI1NkwyOTguMywyNTZMMTMxLjEsODEuOWMtNC4yLTQuMy00LjEtMTEuNCwwLjItMTUuOGwyOS45LTMwLjZjNC4zLTQuNCwxMS4zLTQuNSwxNS41LTAuMmwyMDQuMiwyMTIuNyAgYzIuMiwyLjIsMy4yLDUuMiwzLDguMWMwLjEsMy0wLjksNS45LTMsOC4xTDE3Ni43LDQ3Ni44Yy00LjIsNC4zLTExLjIsNC4yLTE1LjUtMC4yTDEzMS4zLDQ0NmMtNC4zLTQuNC00LjQtMTEuNS0wLjItMTUuOCAgTDI5OC4zLDI1NnoiLz48L3N2Zz4=) no-repeat center;
			background-size: 18px;
			opacity: 0.25;
		}
		
	}
    
}

.sidebar {
	background: #323945;
	overflow: auto;
	width: 300px;
	padding: 20px 30px;
	display: flex;
	flex-direction: column;
	
	.title {
		overflow: auto;
		color: $light;
		border-bottom: none;
		margin: 1.5rem 0 3rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      
        img.logo {
            height: 64px;
            width: 64px;
            border-radius: 23%;
        }
      
        h1 {
            margin: 0 0 0 1.5rem;
            font-size: 2.1rem;
            line-height: 2.8rem;
          
            span {
                display: block;
                font-size: 1.5rem;
                font-weight: 300;
            }
          
        }
      
	}
	
	.nav-list {
		list-style-type: none;
		margin: 0 -30px;
		padding: 0;
		color: #5a6169;
		color: $light;
		font-size: 1.7rem;
		
		.seperator {
			background: rgba(255,255,255,0.05);
			height: 1px;
			margin: 15px -30px;
		}
		
		.nav-list-item {
			
			a {
				text-decoration: none;
				padding: 12px 34px;
				display: block;
				font-weight: 500;
				cursor: pointer;
				
				&:hover {
					color: darken($border, 10%);
				}
				
			}
			
			&.nav-list-seperate {
				border-top: solid 1px darken($border, 48%);
				margin-top: 40px;
                padding-top: 16px;
			}
			
		}
		
	}
	
	.current-user {
		color: $border;
		font-size: 1.4rem;
	}
	
}

.loading {
	display: block;
	height: 24px;
	width: 24px;
	margin: 3rem 2rem;
}

/* Typography
-------------------------------------------------------*/

h1, .h1 {
	font-size: 2.4rem;
	font-weight: 500;
	margin-bottom: 2.2rem;
}

h3, .h3 {
	font-size: 1.7rem;
	font-weight: 500;
}

/* Forms 
-------------------------------------------------------*/

.form {
	
    .button-bar:last-child {
        margin-top: 4rem;
    }
  
	.form-field {
		margin: 1.5rem 0 1.5rem;
		
		& + .button-bar {
			margin-top: 4rem;
		}
		
	}
	
	.form-field-group {
		display: flex;
		margin: 0 -1rem;
		
		.form-field {
			flex: 1;
			margin: 1.5rem 1rem 0;
		}
		
		& + .button-bar {
			margin-top: 4rem;
		}
	}
	
	.change-password-fields {
		border-top: solid 1px $light;
		margin-top: 3.5rem;
		padding-top: 1rem;
		
		& + .button-bar {
			margin-top: 4rem;
		}
		
	}
  
    .self-assessment-step {
        border: 1px solid $border;
        border-radius: 4px;
        padding: 4px 22px 28px;
        overflow: auto;
        background: #f4f7f9;
        //margin: 2.5rem 0 2rem;
        margin: 1.25rem 0 0;
      
        & + .self-assessment-step {
            margin-top: -1rem;
        }
      
        .title {
		    border-bottom: solid 1px #e3e7ed;
		    margin-bottom: 2rem;
            display: flex;
            align-items: center;
          
            .drag-me {
                margin: 0 1rem 0 -1rem;
            }
          
            h2 { 
                font-size: 1.8rem;
                font-weight: 500;
                margin: 1.5rem 5rem 1.5rem 0;
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
          
            .button.button-toggle {
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQwIiBoZWlnaHQ9IjEwMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxnPiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+IDwvZz4gPGc+ICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+ICA8cGF0aCBmaWxsPSIjNWE2MTY5IiBpZD0ic3ZnXzEiIGQ9Im0zODQsNDQ4bDAsLTI1NmwtMTI4LDBsMCwyNTZsLTI1NiwwbDAsMTI4bDI1NiwwbDAsMjU2bDEyOCwwbDAsLTI1NmwyNTYsMGwwLC0xMjhsLTI1NiwweiIvPiA8L2c+PC9zdmc+) center no-repeat;
                background-size: 14px;
                height: 32px;
                width: 32px;
                padding: 0;
                font-size: 4rem;
                line-height: 32px;
              
                &.button-toggle-open {
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjEwMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxnPiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+IDwvZz4gPGc+ICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+ICA8cGF0aCBmaWxsPSIjNWE2MTY5IiBpZD0ic3ZnXzEiIGQ9Im0wLDQ0OGwwLDEyOGw1MTIsMGwwLC0xMjhsLTUxMiwweiIvPiA8L2c+PC9zdmc+);
                }
              
            }
          
        }
      
        .page-links-list {
            margin: 8px 0 0 0;
            padding: 0 0 0 20px;
            
            .page-links-list-item {
                border-bottom: solid 1px #e3e7ed;
                padding: 10px 5px 10px 0;
              
                .page-links-list-item-inner {
                    display: flex;
                  
                    & > span {
                        flex: 1;
                    }
                  
                }
              
                .btn-remove-link {
                    display: inline-block;
                    appearance: none;
                    height: 24px;
                    width: 24px;
                    vertical-align: middle;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjEyIiBoZWlnaHQ9Ijc5MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPGc+ICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+ICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4gPC9nPiA8Zz4gIDx0aXRsZT5MYXllciAxPC90aXRsZT4gIDxwb2x5Z29uIGZpbGw9IiM1YTYxNjkiIGlkPSJzdmdfMiIgcG9pbnRzPSIzODIuMiwzOTYuNCA1NjAuOCwyMTcuOCA0ODQsMTQxIDMwNS40LDMxOS42IDEyNi44LDE0MSA1MCwyMTcuOCAyMjguNiwzOTYuNCA1MCw1NzUgMTI2LjgsNjUxLjggICAgMzA1LjQsNDczLjIgNDg0LDY1MS44IDU2MC44LDU3NSAzODIuMiwzOTYuNCAgIiBjbGFzcz0ic3QwIi8+IDwvZz48L3N2Zz4=) no-repeat center;
                    background-size: 13px;
                    border: none;
                    display: none;
                    cursor: pointer;
                }
              
                &:hover {

                    .btn-remove-link {
                        display: block;
                    }

                }
              
            }
          
        }
      
        & > div > .button-danger:last-child {
            margin-top: 3rem;
            float: right;
        }
      
        &.self-assessment-step-closed {
            padding-bottom: 0;
          
            .title {
                border-bottom: none;
                margin-bottom: 0.5rem;
            }
          
        }
      
    }
	
}

/* Inputs 
-------------------------------------------------------*/

.input-label, .rdw-link-modal-label {
	display: block;
	font-size: 1.6rem;
	margin: 0 0 0.75rem 0;
	
	& + .input-label-note {
		margin-top: -0.5rem;	
	}
	
	&.input-label-note {
		font-size: 1.4rem;
		margin-bottom: 1.3rem;
		color: lighten(#5a6169, 20%);
	}
	
    &.input-checkbox-label {
        display: flex;
        align-items: center;
        margin: 0 0 1.5rem 0;
        
        input[type=checkbox] {
            margin: 0 1.2rem 0 0;
        }
      
    }
  
}

.text-input, .rdw-link-modal-input {
	//padding: .5rem 1rem;
	padding: 8px 14px;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #495057;
    background-color: white;
    border: 1px solid $border;
    border-radius: 4px;
    box-shadow: none;
	
	&.text-input-block {
		width: 100%
	}
	
	&.search-input {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTczLjEyNDk5OTk5OTk5OTkiIGhlaWdodD0iMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPGc+ICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+ICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4gPC9nPiA8Zz4gIDx0aXRsZT5MYXllciAxPC90aXRsZT4gIDxwYXRoIGZpbGw9IiNiZWNhZDYiIGlkPSJzdmdfMSIgZD0ibTk2MCw4MzJsLTI0OS4xMjUsLTI0OS4xMjVjMzUuNTYzLC01OC4wNjMgNTcuMTI1LC0xMjUuNzE5IDU3LjEyNSwtMTk4Ljg3NWMwLC0yMTIuMDMxIC0xNzIsLTM4NCAtMzg0LC0zODRjLTIxMi4wMzEsMCAtMzg0LDE3MS45NjkgLTM4NCwzODRjMCwyMTIgMTcxLjk2OSwzODQgMzg0LDM4NGM3My4xNTYsMCAxNDAuODEyLC0yMS41NjIgMTk4Ljg3NSwtNTdsMjQ5LjEyNSwyNDljMTcuNSwxNy41IDQ2LjUsMTcuMzc1IDY0LDBsNjQsLTY0YzE3LjUsLTE3LjUgMTcuNSwtNDYuNSAwLC02NHptLTU3NiwtMTkyYy0xNDEuMzc1LDAgLTI1NiwtMTE0LjYyNSAtMjU2LC0yNTZzMTE0LjYyNSwtMjU2IDI1NiwtMjU2czI1NiwxMTQuNjI1IDI1NiwyNTZzLTExNC42MjUsMjU2IC0yNTYsMjU2eiIvPiA8L2c+PC9zdmc+) no-repeat center right 16px;
		background-size: 16px;
		padding-right: 40px;
	}
	
    &.number-input {
        width: 70px;
        margin-right: 1rem;
    }
  
	&:disabled {
		background: #f4f4f4;
		border-color: #ddd;
		cursor: not-allowed;
	}
	
    &::-webkit-input-placeholder {
        color: #909da8;
    }
  
}

.select-box {
	height: 42px;
	padding: 8px 44px 8px 14px;
	font-size: 1.6rem;
    line-height: 1.2;
    color: #495057;
    background: #fff url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNTEyIDUxMiIgaGVpZ2h0PSI1MTJweCIgaWQ9IkxheWVyXzEiIHZlcnNpb249IjEuMCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHdpZHRoPSI1MTJweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGc+PHBvbHlnb24gcG9pbnRzPSIyNTYsMzgyLjQgNjYuOCwxOTMuMiAxMDkuMiwxNTAuOCAyNTYsMjk3LjYgNDAyLjgsMTUwLjggNDQ1LjIsMTkzLjIgICIvPjwvZz48L3N2Zz4=) no-repeat center right 15px;
	background-size: 20px;
    border: 1px solid $border;
    font-size: 1.6rem;
    border-radius: 4px;
	appearance: none;
	
	&.select-box-block {
		width: 100%
	}
	
}

/* Wysiwig Editor 
-------------------------------------------------------*/

.rdw-editor-wrapper {
	border: 1px solid $border;
    border-radius: 4px;
	
	.rdw-editor-toolbar {
		border: none;
		border-bottom: 1px solid $border;
		margin-bottom: 0;
		padding: 12px 5px 6px;
		background: white;
        position: relative;
        z-index: 2;
        border-radius: 4px 4px 0 0;
		
		.rdw-option-wrapper {
			border: none;
			min-width: 30px;
			height: 30px;
			
			&:hover {
				border: solid 1px $border;
				padding: 4px;
				box-shadow: none;
				background: $light;
			}
			
			&.rdw-option-image, &.rdw-option-video {
				position: relative;
				
				img {
					width: 20px;
					height: auto;
					vertical-align: middle;
				}
				
				input[type="file"] {
					height: 0;
					width: 0;
					position: absolute;
				}
				
			}
			
			&.rdw-option-video img {
				width: 17px;	
			}
			
		}
		
	}
	
	.rdw-editor-main {
		padding: 0 14px 4px;
		font-size: 1.6rem;
	}
	
	.rdw-image-alignment-options-popup {
		display: none!important;
	}
	
	.rdw-link-modal {
		box-shadow: none;
		height: auto;
		padding: 20px;
		box-shadow: 0px 5px 40px rgba(0,0,0,0.15);
		
		&:before {
			content: '';
			position: absolute;
			top: -8px;
			left: 0;
			right: 0;
			height: 16px;
			width: 16px;
			margin: auto;
			background: white;
			transform: rotate(45deg);
			border-radius: 4px;
		}
		
		.rdw-link-modal-input {
			height: auto;
			padding: 8px 14px;
			margin: 0 0 1.5rem 0;
			border-radius: 4px;
			border: 1px solid $border;
		}
		
		.rdw-link-modal-target-option {
			display: none!important;
		}
		
		.rdw-link-modal-buttonsection {
			display: flex;
			margin: 0 -5px;
			
			.rdw-link-modal-btn {
				width: auto;
				flex: 1;
				margin: 0 5px;
				height: 40px;
			}
			
		}
		
	}
  
    .rdw-image-center {
        justify-content: flex-start;
    }
  
    .rdw-image-imagewrapper {
        width: 375px;
      
        img {
            width: 100%!important;
            height: auto!important;
        }
      
    }
  
    iframe {
        width: 375px;
        height: 211px;
    }
  
    figure {
        margin: 0;
    }
	
    .public-DraftEditor-content > div > div:not(:only-child) br:only-child {
        //display: none;
    }
  
}

/* Buttons 
-------------------------------------------------------*/

.button-bar {
	display: flex;
	margin: 0 -0.6rem;
    align-items: center;
	
	.button {
		margin: 0 0.6rem;
		
		&.button-align-end {
			margin-left: auto;
            //margin-right: 0;
		}
		
	}
  
    .loading {
        margin: 0 0 0 1rem;
        width: 20px;
    }
	
}

.button {
	//padding: .75rem 1.75rem .85rem;
    padding: .75rem 0 .85rem;
    font-size: 1.6rem;
    line-height: 1.5;
	appearance: none;
	border-radius: 4px;
	border: none;
	text-decoration: none;
	display: inline-block;
	
    &.button-primary, 
    &.button-secondary, 
    &.button-danger, 
    &.button-outline {
        padding: .75rem 1.75rem .85rem;
    }
  
	&.button-primary {
		//background-color: #63d64f;
        background-color: #72dd5f;
		color: white;
		
		&.button-outline {
			border-color: #516578;
			color: #516578;
			background-color: transparent;
		}
		
	}
	
	&.button-secondary {
		background-color: #516578;
		color: white;
	}
	
	&.button-danger {
		background-color: #f53d3d;
		color: white;
		
		&.button-outline {
			padding: .65rem 1.5rem;
			border-color: #f53d3d;
			color: #f53d3d;
			background-color: transparent;
		}
		
	}
	
	&.button-outline {
		border: solid 1px;
	}
}

/* List 
-------------------------------------------------------*/

.drag-me {
    position: relative;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48Zz48cmVjdCBoZWlnaHQ9IjMyIiB3aWR0aD0iNTEyIiB5PSIxNDQiLz48cmVjdCBoZWlnaHQ9IjMyIiB3aWR0aD0iNTEyIiB5PSIyNDAiLz48cmVjdCBoZWlnaHQ9IjMyIiB3aWR0aD0iNTEyIiB5PSIzMzYiLz48L2c+PC9zdmc+) center no-repeat;
    background-size: 20px;
    height: 40px;
    min-width: 40px;
}

.contents-list {
	list-style-type: none;
	margin: 0 0 4rem 0;
	padding: 0;
	
	.contents-list-item {
		
		.list-item-inner {
			display: flex;
			border-bottom: solid 1px $light;
			align-items: center;

            p > a {
                padding: 0;
                display: block;
            }
          
			a {
				display: inline-block;
				padding: 12px 0;
				text-decoration: none;
				position: relative;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				
				a:first-of-type {
					flex: 1;
				}
				
				&:hover {
					text-decoration: underline;
				}
				
				& + a {
					margin: 0 0 0 2rem;
					padding-left: 2rem;
					width: 140px;
					
					&:before {
						content: '';
						position: absolute;
						top: 14px;
						bottom: 14px;
						left: 0;
						width: 1px;
						background: #5a6169;
					}
					
				}

			}
			
			.drag-me {
				margin: auto 12px auto 0;
			}
			
			.toggle-sub-list-label {
				cursor: pointer;
				min-width: 36px;
				text-align: center;
				font-size: 2rem;
				font-weight: bold;
				height: 48px;
				line-height: 48px;
			}

		}

		.toggle-sub-list {
			display: block;
			visibility: hidden;
			height: 0;
			width: 0;
		}
		
		.toggle-sub-list:checked + .contents-list {
			display: block;
		}
		
		.contents-list {
			margin: 0 0 0 2.4rem;
			//display: none;
		}
		
	}
	
}

/* Login 
-------------------------------------------------------*/

.login {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
    background: #eff2f7;
  
	.form {
		min-width: 340px;
        background: white;
        padding: 40px;
        box-shadow: 0px 10px 15px -10px rgba(0,0,0,0.25);
		
		h1 {
			margin: 0;
		}
		
	}
  
    .title {
		overflow: auto;
		margin: 0 0 3rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: solid 1px $light;
        padding: 0 0 2.5rem 0;
      
        img.logo {
            height: 60px;
            width: 60px;
            border-radius: 23%;
        }
      
        h1 {
            margin: 0 0 0 1.5rem;
            font-size: 2.1rem;
            line-height: 2.8rem;
          
            span {
                display: block;
                font-size: 1.5rem;
                font-weight: 300;
            }
          
        }
      
	}
	
}

/* Tabs 
-------------------------------------------------------*/

.tabs {
	display: flex;
	margin: 0 0 -1px 0;
	
	.tab {
		text-align: center;
		padding: 13px 20px;
		border: solid 1px $light;
		border-radius: 4px 4px 0 0;
		background-color: $light;
		cursor: pointer;
		margin: 0 3px 0 0;
		
		&.tab-active {
			background-color: white;
			border-bottom-color: white;
		}
		
	}
	
}